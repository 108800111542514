import React, { useState, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { GlobalStyle } from '../../styles/StyledComponents';
import Navigation from '../../components/Navigation';
import '../../index.css';
import reportWebVitals from '../../reportWebVitals';
import { LocationContext } from '../../App';

const DmvHomePage = lazy(() => import('./HomePage'));
const ExamPage = lazy(() => import('../../pages/ExamPage'));
const MockTestPage = lazy(() => import('../../pages/MockTestPage'));
const ResultsPage = lazy(() => import('../../pages/ResultsPage'));
const LoginPage = lazy(() => import('../../pages/LoginPage'));
const SignupPage = lazy(() => import('../../pages/SignupPage'));
const About = lazy(() => import('../../pages/About'));
const Terms = lazy(() => import('../../pages/Terms'));
const Privacy = lazy(() => import('../../pages/Privacy'));
const ArticlesHomePage = lazy(() => import('./ArticlesHome'));
const ArticlesExamPage = lazy(() => import('../Articles/Exam'));
const ArticlesCategoryPage = lazy(() => import('../Articles/Category'));
const ArticlesArticlePage = lazy(() => import('../Articles/Article'));

function App() {
  const [userLocation, setUserLocation] = useState('US');
  return (
    <LocationContext.Provider value={{ userLocation, setUserLocation }}>
      <GlobalStyle />
      <Navigation />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </LocationContext.Provider>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <DmvHomePage /> },
      { path: 'exam/:examId', element: <ExamPage /> },
      { path: 'mocktest/:testId', element: <MockTestPage /> },
      { path: 'results/:testId', element: <ResultsPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'signup', element: <SignupPage /> },
      {path: 'about', element: <About /> },
      {path: 'terms', element: <Terms /> },
      {path: 'privacy', element: <Privacy /> },
      {
        path: 'articles',
        children: [
          { index: true, element: <ArticlesHomePage /> },
          { path: ':examId', element: <ArticlesExamPage /> },
          { path: ':examId/:categorySlug', element: <ArticlesCategoryPage /> },
          { path: ':examId/:categorySlug/:articleSlug', element: <ArticlesArticlePage /> }
        ]
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
