import React from 'react';
import { useContext } from 'react';
import { LocationContext } from '../App';
import { useNavigate, useLocation } from 'react-router-dom';
import { MapPin } from 'lucide-react';

export default function LocationSelector() {
  const { userLocation, setUserLocation, isLoading } = useContext(LocationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const locations = [
    { code: 'US', name: 'United States' },
    { code: 'IN', name: 'India' },
    { code: 'UK', name: 'United Kingdom' }
  ];

  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setUserLocation(newLocation);
    
    const currentPath = location.pathname;
    
    if (currentPath.includes('/region/')) {
      const newPath = currentPath.replace(/\/region\/\w+/, `/region/${newLocation.toLowerCase()}`);
      navigate(newPath);
    }
  };
  
  if (isLoading || !userLocation) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
    <MapPin className="h-4 w-4 text-gray-500" />
    <select 
      value={userLocation}
      onChange={handleLocationChange}
      className="bg-transparent text-gray-700 font-medium py-2 px-3 rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors duration-200"
    >
      {locations.map(location => (
        <option key={location.code} value={location.code}>
          {location.name}
        </option>
      ))}
    </select>
  </div>
  );
}
