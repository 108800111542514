// src/styles/StyledComponents.js
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to right, #f0f0f0, #ffffff);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  @keyframes moveLeftToRight {
    0% { transform: translateX(-50%); }
    100% { transform: translateX(0); }
  }
`;

export const Container = styled.div`
  backgroundColor: 'rgba(255, 255, 255, 0.9)', 
  padding: '30px', 
  margin: '20px',
  borderRadius: '15px',
  boxShadow: '0 8px 32px rgba(52, 152, 219, 0.1)',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(52, 152, 219, 0.18)',  
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // @media (min-width: 1024px) {
  //   padding: 40px;
  // }

  @media (max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`;

export const MockTestContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 1rem;
`;

export const ScrollableQuestions = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

export const Title = styled.h1`
  color: #3498db;
  margin-bottom: 20px;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

export const SubTitle = styled.h2`
  color: #34495e;
  margin-bottom: 15px;
  font-size: clamp(1.25rem, 2vw, 1.75rem);
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #3498db;
  border-radius: 4px;
  color: #2c3e50;
`;

export const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #155db1;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
`;

export const QuestionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const QuestionsContainer = styled.div`
  flex-grow: 1;
  padding: 16px;
`;

export const QuestionText = styled.div`
  margin-bottom: 15px;
  line-height: 1.6;
  color: #333;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const QuestionImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  @media (min-width: 768px) {
    max-height: 400px;
    object-fit: contain;
  }
`;
export const OptionLabel = styled.label`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const OptionText = styled.span`
  display: inline;
  vertical-align: top;
  margin-left: 5px;
`;

export const OptionImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

export const ResultsOptionText = styled.span`
  flex: 1;
  color: ${props => props.color};
`;

export const AnswerIndicator = styled.span`
  font-weight: bold;
  margin-left: 10px;
`;

export const TimerDisplay = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #3498db;
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

export const Card = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 16px;
  margin: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const SquareCard = styled.div`
  width: 200px;
  height: 200px;
  background-color: #ecf0f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
`;

export const CardTitle = styled.div`
  padding: 10px;
  text-align: center;
  color: #3498db;
`;

export const MovingCardsInner = styled.div`
  display: flex;
  gap: 20px;
  animation: moveLeftToRight 300s linear infinite;
  width: max-content;
  will-change: transform;
  transition: animation-play-state .5s ease;
`;

export const MovingCardsContainer = styled.div`
  overflow: hidden;

  &:hover ${MovingCardsInner} {
    animation-play-state: paused;
  }
`;

export const NoResultsMessage = styled.div`
  color: #666;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const TestContainer = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: #3498db;

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }

  .test-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .test-message {
    font-size: 0.9em;
    color: #555;
  }
`;

export const ScoreSection = styled.div`
  text-align: center;
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const ScoreValue = styled.h2`
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 0.5rem;
`;

export const ScoreLabel = styled.p`
  font-size: 1.2rem;
  color: #495057;
  margin-bottom: 1rem;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    width: ${props => props.percentage}%;
    height: 100%;
    background-color: #28a745;
    transition: width 0.5s ease-in-out;
  }
`;

export const SuccessMessage = styled.p`
  background-color: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
`;

export const EncouragementMessage = styled.p`
  background-color: #fff3cd;
  color: #3498db
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 50px;
  color: #444444;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: white;

  &:focus {
    border-color: #5fcf80;
    box-shadow: 0 0 0 2px rgba(95, 207, 128, 0.2);
  }
`;

export const ExplanationContainer = styled.div`
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-left: 4px solid #3498db;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const ExplanationTitle = styled.div`
  font-weight: bold;
  color: #3498db;
  margin-bottom: 8px;
  font-size: 1.1rem;
`;

export const ExplanationText = styled.div`
  color: #495057;
  line-height: 1.6;
  white-space: pre-wrap;
  word-break: break-word;
  
  a {
    color: #3498db;
    text-decoration: underline;
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 4px;
  }
`;
